import { initializeApp } from 'firebase/app';
import {getFirestore} from 'firebase/firestore'

// test
  // const firebaseConfig = {
  //   apiKey: "AIzaSyBT3tE75kml2xjX0vRULqtXG0DdiujvPIY",
  //   authDomain: "lms-test-69f70.firebaseapp.com",
  //   projectId: "lms-test-69f70",
  //   storageBucket: "lms-test-69f70.appspot.com",
  //   messagingSenderId: "1028998362082",
  //   appId: "1:1028998362082:web:12f97a9ae381722d297fbb"
  // };

// live
  const firebaseConfig = {
    apiKey: "AIzaSyClchOmUsH6r20FmoH-pqvjNnAP6owR6R4",
    authDomain: "naboskaber-stjernekaster-todo.firebaseapp.com",
    projectId: "naboskaber-stjernekaster-todo",
    storageBucket: "naboskaber-stjernekaster-todo.appspot.com",
    messagingSenderId: "942369836225",
    appId: "1:942369836225:web:4e46185f70f0d5650a1840"
  };

const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp)